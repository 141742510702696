.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .container {
    max-width: 100%;

    width: 100%;
    .header {
      background: #f6f7f8;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .heading h2 {
        text-transform: uppercase;
        font-size: 20px;
        color: #232323;
        margin-top: 5px;
        cursor: pointer;
      }
      .category {
        display: flex;
        margin-top: 5px;
        h3 {
          text-transform: capitalize;
          margin-right: 10px;
          color: #232323;
          font-size: 18px;
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: #cd1e76;
          }
        }
      }
    }
    .products {
      max-width: 100%;
      .container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr); /* Sửa cú pháp */
        gap: 20px;
        .box {
          //   width: 18%;
          // height: 295px;
          margin-top: 20px;
          height: 425px;
          // padding: 10px 10px;
          border: 4px solid #f6f7f8;

          overflow: hidden;
          border-radius: 5px;
          cursor: pointer;
          &:hover .img_box .icon {
            margin-left: -17%;
          }

          .img_box {
            display: flex;
            // width: 100%;

            img {
              width: 100%;
              height: 250px;
              object-fit: cover;
              transition: 0.3s;
            }
            .icon {
              display: flex;
              flex-direction: column;
              margin-left: 30%;
              margin-top: 10px;
              transition: 0.3s;
              .icon_box {
                padding: 10px 10px;
                margin-top: 10px;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                z-index: 1;
                background: #fff;
                color: #701835;
                transition: 0.5s;
                &:hover {
                  background: #cd1e76;
                  color: white;
                }
              }
            }
          }
          .info {
            padding: 10px;
            margin-left: 10px;
            a {
              text-decoration: none;
            }
            h3 {
              text-transform: capitalize;
              font-size: 16px;

              font-weight: 500;
              color: #232323;
              letter-spacing: 1px;
            }
            p {
              color: #cd1e76;
              margin-top: 5px;
              font-size: 14px;
              letter-spacing: 1px;
              font-weight: bold;
            }
            .btn {
              padding: 5px 10px;
              margin-bottom: 0;

              margin-left: 5px;
              align-items: center;
              border: none;
              background: #fed700;
              color: #232323;
              text-transform: capitalize;
              cursor: pointer;
              border-radius: 5px;
              transition: 0.3s;
              &:hover {
                background: #cd1e76;
                color: white;
              }
            }
          }
        }
      }
      button {
        margin-top: 20px;
        margin-left: 45%;
        padding: 10px 30px;
        background: none;
        outline: none;
        border: none;
        color: #fff;
        font-weight: 600;
        background: #cd1e76;
        cursor: pointer;
      }
    }
  }
  .banner-offers {
    width: 100%;
    margin-top: 20px;
    .banner-title {
      margin-bottom: 20px;
      h2 {
        font-size: 30px;
      }
    }
    .box-banner-img {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* Sửa cú pháp */
      gap: 20px;
      .banner-img {
        cursor: pointer;
        height: 532px;
        border-radius: 15px;
        overflow: hidden;
        img {
          height: 100%;
        }
      }
    }
  }
}
