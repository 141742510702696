.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .introduce {
    width: 100%;
    padding: 0 40px;
    .title {
      font-size: 35px;
      margin-bottom: 20px;
    }
    .part {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}
