.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .product-detail {
    width: 100%;
    .container {
      width: 100%;
      .box-detail {
        margin-bottom: 50px;
        display: flex;
        .box-img {
          overflow: hidden;
          flex: 1;

          padding: 20px 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          height: auto;
          .image {
            width: 100%;
            img {
              object-fit: contain;
              width: 100%;
              height: 450px;
            }
          }
          .active {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
            border-radius: 8px; // Làm góc mềm mại (tùy chọn)
          }
          .list-img {
            margin-top: 10px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            .list-item {
              width: 100%;
              height: 150px;

              transition: all 0.3s ease-in-out;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
        }
        .box-info {
          padding: 30px;
          flex: 1;
          h2 {
            text-transform: capitalize;
          }
          .icon {
            margin-top: 3px;
            span {
              font-size: 15px;
              font-weight: 400;
            }
            .star {
              font-size: 13px;
              color: red;
            }
          }
          .seen {
            font-size: 13px;
            color: #a8acaf;
            font-weight: bold;
            font-style: italic;
          }
          .price {
            font-size: 25px;
            font-weight: 700;
            color: red;
            margin-top: 10px;
          }
          .quality {
            color: #232323;
            font-weight: 400;
            font-style: oblique;
            margin-bottom: 10px;
          }
          .desc {
            font-size: 14px;
            font-style: oblique;
            line-height: 17px;
          }
          .add {
            cursor: pointer;
            margin-top: 20px;
            padding: 10px 20px;
            font-size: 16px;
            font-weight: 600;
            background: #232323;
            border: none;
            outline: none;
            color: yellow;
            transition: 0.2s;
            &:hover {
              background: #75026c;
              color: white;
            }
          }
        }
      }
    }
  }
}
