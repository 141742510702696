// .container {
//   width: 500px;
//   margin: 20px auto;
//   font-family: Arial, sans-serif;
// }

// /* Tabs container */
// .bloc-tabs {
//   display: flex;
//   cursor: pointer;
//   justify-content: space-between;
//   border-bottom: 1px solid #ccc;
// }

// /* Individual tab */
// .tabs {
//   flex: 1;
//   text-align: center;
//   padding: 10px;
//   background: #f4f4f4;
//   border: 1px solid #ccc;
//   border-bottom: none;
//   transition: background 0.3s ease-in-out;
// }

// .tabs:hover {
//   background: #e9e9e9;
// }

// /* Active tab */
// .active-tabs {
//   background: white;
//   border-bottom: 1px solid white; /* Merge seamlessly with content */
//   position: relative;
// }

// /* Active tab underline effect */
// .active-tabs::before {
//   content: "";
//   display: block;
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   transform: translateX(-50%);
//   width: calc(100% + 2px);
//   height: 5px;
//   background: rgb(88, 147, 241); /* Blue accent */
// }

// /* Content container */
// .content-tabs {
//   border: 1px solid #ccc;
//   padding: 20px;
//   background: white;
// }

// /* Default hidden content */
// .content {
//   display: none;
// }

// /* Active content */
// .active-content {
//   display: block;
// }

// /* Heading styles */
// .content h2 {
//   font-size: 20px;
//   font-weight: bold;
//   margin-bottom: 10px;
// }

// /* Separator line */
// .content hr {
//   width: 50px;
//   height: 2px;
//   background: rgb(88, 147, 241); /* Match accent color */
//   border: none;
//   margin-bottom: 10px;
// }

// /* Content text */
// .content p {
//   color: #666;
//   line-height: 1.6;
// }
.container-news {
  width: 100%;
  padding: 20px;
  margin-top: 40px;
  background: rgba(238, 235, 235, 0.712);
  border-radius: 10px;
  .news {
    h4 {
      cursor: pointer;
      color: blue;
      text-align: center;
    }
    width: 100%;
    .news-title {
      margin-bottom: 20px;
      font-size: 18px;
    }
    .bloc-tabs {
      display: flex;
      .tabs {
        &:nth-child(2) {
          margin-left: 20px;
        }
        button {
          border: 1px solid rgb(143, 142, 142);
          border-radius: 10px;
          padding: 10px;
          background: transparent;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            background: rgba(167, 164, 164, 0.8);
          }
        }
      }
    }
    .content-tabs {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      gap: 10px;
      .box-new {
        cursor: pointer;
        width: 100%;
        // border-radius: 20px;

        // overflow: hidden;
        height: 300px;
        .img-new {
          width: 100%;

          img {
            width: 100%;
            height: 190px;
            border-radius: 20px;
          }
        }
        .info-new {
          a {
            text-decoration: none;
            color: #232323;
          }
        }
      }
    }
  }
}
.congnghe {
  display: none;
}

.active-content {
  display: block;
}
.active-tabs {
  border: 3px solid black;
  border-radius: 15px;
}
