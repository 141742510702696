.max_container {
  max-width: 1404px;
  width: 100%;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  padding: 20px;
  margin: auto;
}
.container-style {
  height: 100%;
  position: relative;
}

.next-slider {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 42%;
  bottom: 20px;

  span {
    width: 45px;
    height: 12px;
    background-color: rgb(179, 178, 176);
    margin-left: 10px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 3px 2px 2px rgba(73, 72, 72, 0.338);
    &.active {
      background-color: rgba(32, 32, 34, 0.596);
    }
  }
}
