.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .shop {
    width: 100%;
    h2 {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #232323;
      font-size: 32px;
      font-weight: 300;
    }
    p {
      margin-top: 5px;
      color: #8a8a8a;
      font-size: 14px;
      font-weight: 600;
    }
    .container {
      max-width: 100%;
      display: flex;
      margin-top: 20px;
      .left-box {
        width: 20%;
        .category {
          border: 3px solid #f6f6f6;
          width: 250px;
          .header {
            max-width: 100%;
            padding: 10px 20px;
            background: #f23534;
            h3 {
              font-size: 16px;
              color: #ffffff;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }

          .box {
            margin-top: 10px;
            width: 100%;
            // padding: 10px 20px;
            ul {
              max-width: 100%;
              transform: translateX(-20px);
              li:nth-last-child(1) {
                border-bottom: none;
                padding-bottom: 20px;
              }

              li {
                border-bottom: 1px solid black;
                list-style: none;
                padding: 10px 0px;
                text-transform: uppercase;
                margin-top: 10px;
                font-size: 14px;
                font-weight: bold;
                color: #231f20;
                letter-spacing: 1px;
                cursor: pointer;
                transition: 0.2s;
                &:hover {
                  color: #f23534;
                }
              }
            }
          }
        }
        .banner {
          margin-top: 20px;
          width: 250px;
          .img_box img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .right-box {
        width: 79%;
        .banner {
          width: 100%;
          .img_box img {
            max-width: 100%;
            object-fit: cover;
            border-radius: 10px;
          }
        }

        .product_box {
          width: 100%;
          margin-top: 30px;
          h2 {
            color: #232323;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 22px;
            margin-top: 10px;
          }
          .product_container {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            // grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 20px;
            margin-top: 20px;
            // justify-content: space-between;
            .box {
              border-radius: 10px;
              cursor: pointer;
              width: 100%;
              height: 440px;
              overflow: hidden;
              border: 3px solid #f6f6f6;
              //   &:hover .img_box .icon {
              //     margin-left: -60px;
              //   }
              .img_box {
                display: flex;
                height: 270px;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 270px;
                  object-fit: cover;
                  transition: 0.3s;
                  &:hover {
                    transform: scale(1.2);
                  }
                }
                // .icon {
                //   display: flex;
                //   flex-direction: column;
                //   padding: 10px;
                //   margin-top: 10px;
                //   margin-left: 50px;
                //   transition: 0.5s;
                // }
              }
              .detail {
                padding: 0 10px;
                margin-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                a {
                  text-decoration: none;
                }
                h3 {
                  color: #232323;
                  font-size: 18px;
                  font-weight: 600;
                  text-transform: capitalize;
                  transition: 0.3s;
                  &:hover {
                    color: #df3b38;
                  }
                }
                p {
                  margin-top: 10px;
                  margin-top: 5px;
                  font-weight: 400;
                  color: #232323;
                  font-size: 16px;
                  letter-spacing: 1px;
                }
                .btn {
                  margin-top: 15px;
                  display: flex;
                  justify-content: space-between;
                  .heart {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    border: none;
                    cursor: pointer;
                    width: 40px;
                    border-radius: 50%;
                    transition: 0.25s;
                    &:hover {
                      color: red;
                      background: #ffd400;
                    }
                  }
                  .eye {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    border: none;
                    cursor: pointer;
                    width: 40px;
                    border-radius: 50%;
                    transition: 0.25s;
                    &:hover {
                      a {
                        color: #072af1;
                      }
                      background: #ffd400;
                    }
                    a {
                      color: black;
                    }
                  }
                  .add {
                    border: none;
                    font-size: 14px;
                    outline: none;
                    color: #232323;
                    background: #fed700;
                    font-weight: 600;
                    cursor: pointer;
                    transition: all 0.3s;
                    &:hover {
                      color: #ffce00;
                      background: #232323;
                    }
                  }
                  button {
                    padding: 10px;
                  }
                }
              }
            }
          }
        }
        .seeMore {
          margin-top: 30px;
          cursor: pointer;
          padding: 8px 12px;
          margin-left: 50%;
          font-size: 15px;
          font-weight: bold;
          border: none;
          outline: none;
          background: #f23534;
          color: white;
          transition: 0.2s;
          &:hover {
            background: #232323;
            color: #fed700;
          }
        }
      }
    }
  }
}
