.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  .contact {
    width: 100%;
    .row {
      width: 100%;
      display: flex;
    }
    .left-contact {
      flex: 8;
      padding: 40px;
      h2 {
        font-size: 30px;
        margin-bottom: 20px;
      }
      .box {
        width: 100%;
        .info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .name {
            width: 50%;
            display: flex;
            flex-direction: column;
            input {
              margin-top: 5px;
              width: 100%;
              padding: 10px;
              border-radius: 5px;
              border: none;
              outline: none;
              background: #e4e4e4e1;
              font-size: 17px;
            }
          }
          .email {
            margin-left: 10px;
            width: 50%;
            display: flex;
            flex-direction: column;
            input {
              margin-top: 5px;
              width: 100%;
              padding: 10px;
              border-radius: 5px;
              border: none;
              outline: none;
              background: #e4e4e4e1;
              font-size: 17px;
            }
          }
        }
        .subject {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          input {
            margin-top: 5px;
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: none;
            outline: none;
            background: #e4e4e4e1;
            font-size: 17px;
          }
        }
        .message {
          margin-top: 10px;

          textarea {
            width: 100%;
            // margin-right: 30px;
            border: none;
            outline: none;
            background: none;
            border-bottom: 2px solid #8a8a8a;
            padding: 10px;
            resize: none;
          }
        }
      }
      button {
        margin-top: 20px;
        margin-left: 30px;
        padding: 10px 30px;
        color: #fff;
        border: none;
        font-size: 20px;
        font-weight: bold;
        outline: none;
        background: #232323;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background: #fed700;
          color: #232323;
        }
      }
    }
    .right-contact {
      flex: 4;
      .box-right {
        width: 100%;
        padding: 50px;
        .title {
          padding: 10px 20px;
          background: #f3f3f3;
        }
        ul {
          list-style: none;
          display: flex;
          flex-direction: column;
          margin-left: -40px;
          a {
            text-decoration: none;
          }

          li {
            display: flex;
            align-self: center;
            padding: 10px;
            color: black;
            border-bottom: 1px solid rgba(172, 172, 172, 0.76);
            cursor: pointer;
            transition: 0.2s;
            &:hover {
              color: red;
            }
          }
        }
      }
    }
    display: flex;
  }
}
