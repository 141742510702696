/* Top Bar */
.top-bar {
  background-color: #333;
  color: #fff;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.top-bar-right a {
  color: #fff;
  text-decoration: none;
}

/* Main Nav Bar */
.navbar {
  display: flex;
  align-items: center;
  background-color: #000;
  padding: 10px 20px;
  background-image: url("../../assets/image/dientu.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.box-nav {
  margin: 0 auto;
  width: 1400px;
  display: flex;
  align-items: center;
  // background-color: #000;
  padding: 10px 20px;
}

.logo {
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  margin-right: 20px;
}

.search-input {
  padding: 8px;
  border: none;
  border-radius: 4px;
  flex: 1;
}

.search-button {
  background-color: red;
  color: #fff;
  padding: 8px 15px;
  border: none;
  margin-left: 5px;
  cursor: pointer;
}

.auth-buttons {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.login-button,
.register-button {
  background-color: white;
  color: black;
  padding: 5px 10px;
  border: none;
  margin: 0 5px;
  cursor: pointer;
}

.cart-icon {
  color: white;
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cart-icon span {
  margin-left: 5px;
}

/* Navigation Links */
.nav-links {
  display: flex;
  justify-content: center;
  background-color: #333;
  padding: 10px 0;
  span {
    margin-left: 5px;
  }
  a {
    text-decoration: none;
    color: #333;
    font-weight: normal;
    padding: 10px 15px;
  }
  a.active {
    font-weight: bold;
    color: red;
    border-bottom: 2px solid red;
  }
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  font-weight: bold;
}

.nav-links a:hover {
  color: red;
}

/* Info Row */
.info-row {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: #f2f2f2;
  font-size: 14px;
  gap: 40px;
}

.info-row div {
  display: flex;
  align-items: center;
  color: #333;
}

.info-row div svg {
  margin-right: 5px;
}
.auth-buttons span {
  color: white;
}
