.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .container-slider {
    // margin-top: 40px;
    // padding: 0 30px;

    .slick-prev {
      left: -30px;

      &::before {
        color: black;
        font-size: 30px;
      }
    }
    .slick-next {
      right: -20px;

      &::before {
        color: black;
        font-size: 30px;
      }
    }
  }

  .slider-content {
    background: #fde100;
    padding: 12px 40px;
    border-radius: 10px;
    padding-bottom: 30px;
  }
  .slider-title {
    font-style: italic;
    margin-bottom: 15px;
    margin-left: 10px;
    span {
      color: red;
    }
  }

  .slider_text {
    padding-top: 20px;
    li {
      list-style: none;
      margin-bottom: 6px;
    }
    li:first-child {
      height: 30px;
      width: 70%;
      background: rgb(131, 131, 250);
      display: inline-block;
      margin-bottom: 20px;
      border-radius: 50px;
      p {
        color: white;
        font-size: 12px;
        line-height: 30px;
      }
      img {
        width: 30px;
        float: left;
        margin-right: 6px;
      }
    }
    li:last-child i {
      color: #f97e0e;
    }
    li:nth-child(2):hover {
      color: #f97e0e;
    }
    li:nth-child(4) {
      a {
        text-decoration: line-through;
        color: black;
      }
      span {
        color: #f97e0e;
      }
    }
    li:nth-child(5) {
      font-weight: bold;
      color: #f9510e;
    }
    li:nth-child(6) {
      font-size: 12px;
      color: #5e5d5d;
    }
    li:nth-child(3) {
      font-size: 12px;
      color: #5e5d5d;
    }
  }
  .slider-product-item {
    width: 19%;
    background: white;
    border-radius: 5px;
    padding: 50px 12px 50px;

    .img-1 {
      width: 100%;
      transition: 0.3s all ease;
    }
    &:hover .img-1 {
      transform: translateY(-20px);
    }
  }
  .slick-slide > div {
    //dùng để khoảng cách các slider
    margin: 0 10px;
  }
}
