.max_container {
  max-width: 1400px;
  width: 100%;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
}
.cart {
  width: 100%;
  padding: 20px 30px;
  h3 {
    font-size: 32px;
    text-transform: uppercase;
    color: #232323;
    letter-spacing: 1px;
    font-weight: 300;
  }
  .empty_cart {
    width: 100%;
    text-align: center;
    h2 {
      text-align: center;
      text-transform: uppercase;
      font-size: 18px;
      color: #232323;
      letter-spacing: 1px;
    }
    button {
      padding: 10px 30px;
      margin-top: 10px;
      background: #fed700;
      color: #cd176c;
      border: none;
      outline: none;
      border: 1px solid #fed700;
      font-weight: 600;
      transition: 0.25s;
      cursor: pointer;
      &:hover {
        background: #cd176c;
        a {
          color: #fed700;
        }
      }
      a {
        text-decoration: none;
        color: #cd176c;
      }
    }
  }
  .container {
    max-width: 100%;
    padding: 10px 20px;
    .box {
      display: flex;
      width: 100%;
      border: 2px solid #f6f6f6;
      height: 250px;
      margin-top: 10px;
      .img_box img {
        width: 270px;
        height: 100%;
        object-fit: cover;
      }
      .detail {
        display: flex;
        width: 100%;
        margin-top: 10px;
        margin-left: 30px;
        .info {
          width: 400px;
          margin: 10px;
          margin-left: 60px;
          h4 {
            text-transform: uppercase;
            font-size: 14px;
            color: #8a8a8a;
          }
          h3 {
            margin-top: 10px;
            color: #232323;
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: 600;
          }
          p {
            margin-top: 10px;
            color: #232323;
            text-transform: uppercase;
          }
        }
        .quantity {
          margin-top: 65px;
          margin-left: 70px;
          display: flex;
          button {
            padding: 10px 20px;
            color: #232323;
            border: none;
            outline: none;
            background: #fed700;
            font-weight: 600;
            height: 40px;
            font-size: 16px;
            cursor: pointer;
          }
          input {
            height: 40px;
            padding: 10px 20px;
            background: none;
            border: none;
            border: none;
            outline: none;
            border: 2px solid #fed700;
            color: #cd176c;
            font-size: 14px;
            font-weight: 600;
            width: 100px;
          }
        }
        .icon {
          margin-top: 65px;
          margin-left: 60px;
          li {
            list-style: none;
            background: #cd176c;
            padding: 10px 10px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
  }
  .bottom {
    margin-left: 30px;
    .Total h4 {
      font-size: 22px;

      text-transform: uppercase;
      font-weight: 600;
    }
    button {
      padding: 10px 30px;
      border: none;
      outline: none;
      background: #fed700;
      color: #232323;
      font-weight: 600;
      font-size: 16px;
      margin-top: 20px;
      cursor: pointer;
    }
  }
}
.cart-checkout {
  width: 900px;
  height: 600px;
  border: 3px solid #181717;
  background: #535353;
  position: fixed;
  top: 50px;
  left: 250px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
  padding: 20px 30px;
  .close_btn {
    float: right;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    font-size: 22px;
  }
  .container {
    width: 100%;
    display: flex;
    .form {
      display: flex;
      flex-direction: column;
      width: 500px;
      border: 1px solid black;
      margin: auto;
      padding: 40px;
      max-width: 400px;
      margin: 20px auto;
      padding: 20px;
      background: #f9f9f9;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      h2 {
        margin-bottom: 15px;
        font-size: 24px;
        text-align: center;
        color: #333;
      }
      label {
        display: block;
        margin-bottom: 5px;
        font-weight: bold;
        color: #555;
      }
      input[type="text"] {
        width: 100%;
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 14px;
      }
      /* Custom Select */
      .custom-select {
        position: relative;
        margin-bottom: 15px;
        select {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          appearance: none;
          background: #fff
            url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23bbb" height="24" viewBox="0 0 24 24" width="24"><path d="M7 10l5 5 5-5z"/></svg>')
            no-repeat right 10px center;
          background-size: 16px;
          font-size: 14px;
        }
        /* Trạng thái disabled */
        select:disabled {
          background-color: #f5f5f5;
          cursor: not-allowed;
        }
      }

      button {
        width: 80px;
        padding: 5px 10px;
        cursor: pointer;
        border: none;
        background: #007bff;
        color: white;
        transition: 0.2s;
        &:hover {
          background: #0069d9;
        }
      }
    }
    /* Hover và focus */
    .form input[type="text"]:focus,
    .custom-select select:focus {
      outline: none;
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }
  }
}
