.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .rating {
    .box-rating {
      input[type="radio"] {
        display: none;
      }
      .star {
        cursor: pointer;
        transition: color 200ms;
      }
    }
    .box-comment {
      padding: 10px;
      display: flex;
      margin-top: 20px;
      .box-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        .info-top {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon {
            margin-left: 2px;
          }
          span {
            margin-left: 10px;
            color: #8a8a8a;
            font-style: italic;
          }
        }
        .stars {
          color: #ffc107;
        }
      }
    }
  }
  .comment {
    padding: 0 20px;
    textarea {
      margin-top: 20px;
      width: 100%;
      // margin-right: 30px;
      border: none;
      outline: none;
      background: none;
      border-bottom: 2px solid #8a8a8a;
      padding: 10px;
      resize: none;
    }
  }
}
