.max_container {
  max-width: 1404px;
  width: 100%;
  padding: 20px;
  // padding-left: 15px;
  // padding-right: 15px;
  // background: map.get($theme-colors, "main");
  margin: auto;
  .news-detail {
    .content {
      .news-img {
        width: 700px;
        margin: 20px auto;
        img {
          width: 100%;
        }
      }
      .news-des {
        margin-top: 20px;
      }
    }
  }
}
