.footer {
  background-color: #f8f8f8;
  font-family: Arial, sans-serif;
  color: #333;

  .footer__top {
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;

    .footer__brand-logos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 150px;
        margin-right: 10px;
        max-height: 50px;
      }
    }
    .footer__sub {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .footer__subscribe {
        margin-top: 20px;
        h4 {
          text-transform: uppercase;
          margin-bottom: 10px;
        }

        form {
          display: flex;
          input {
            width: 400px;
            padding: 10px;
            margin-right: 10px;
          }

          button {
            padding: 10px 20px;
            background-color: red;
            color: #fff;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 20px;
              line-height: 20px;
              vertical-align: middle;
              margin-right: 3px;
            }
          }
        }
      }

      .footer__social {
        padding: 20px;
        padding-top: 50px;
        a {
          color: #fff;
          margin: 0px 10px;
          font-size: 35px;
          .facebook {
            color: #47649e;
          }
          .twitter {
            color: #00a0d1;
          }
          .google {
            color: #dd4b39;
          }
        }
      }
    }
  }

  .footer__middle {
    display: flex;
    justify-content: space-around;
    padding: 40px 20px;

    .footer__section {
      max-width: 300px;

      h5 {
        font-size: 20px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
      }

      .footer__payments img {
        margin-right: 10px;
        max-height: 30px;
      }
    }

    .footer__links {
      h5 {
        font-size: 18px;
        margin-bottom: 20px;
      }
      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          a {
            text-decoration: none;
            color: #333;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .footer__contact {
      h5 {
        font-size: 18px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 10px;
      }
      .contact__info {
        display: flex;
        // justify-content: center;
        align-items: center;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          padding: 10px 10px;
          border-radius: 50%;
          border: 1px solid black;
          color: red;
        }
      }
    }
  }

  &__bottom {
    text-align: center;
    padding: 20px;
    background-color: #f0f0f0;
    font-size: 14px;
  }
}
